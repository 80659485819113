<template>
  <div class="judge-management">
    <div class="judge-header">
      <el-button type="primary" @click="createJudge">添加评委</el-button>
    </div>
    <el-table
      class="customTable"
      :data="tableData"
      style="width: 100%; margin-top: 20px; flex: 1"
      height="1%"
      size="medium"
      :header-cell-style="{
        fontWeight: 'normal',
        height: '60px',
        color: '#666666',
        background: '#F6F6F6',
        fontSize: '16px',
      }"
      :cell-style="{ fontSize: '16px', color: '#333333', height: '70px' }"
    >
      <el-table-column prop="nickname" label="评委" width="180">
      </el-table-column>
      <el-table-column prop="name" label="头像" width="180">
        <template slot-scope="scope">
          <div class="column-logo-box">
            <div class="column-logo">
              <img v-if="scope.row.avatar" :src="scope.row.avatar" alt="" />
              <img v-else src="./../../../assets/images/user_img.png" alt="" />
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="username" label="账号"> </el-table-column>
      <el-table-column prop="address" label="状态">
        <template slot-scope="scope">
          <span>{{ scope.row.status == 1 ? "正常" : "禁止" }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-link
            type="success"
            :underline="false"
            @click="editJudge(scope.row)"
            >编辑</el-link
          >
          <el-link
            type="danger"
            :underline="false"
            @click="deleteJudge(scope.row)"
            >删除</el-link
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pager-center"
      style="margin: 20px 0 30px; text-align: center"
      :current-page="judgePages.currentPageNum"
      :page-size="judgePages.eachPageNum"
      :total="judgePages.total"
      layout="prev, pager, next, jumper"
      @current-change="CurrentChange"
    >
    </el-pagination>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="dialogTitle"
      custom-class="green-dialog"
      :visible.sync="judgeDialogVisible"
      @close="resetForm"
      @opened="openJudge"
      width="500px"
    >
      <el-form :model="form" ref="form" :rules="judgeRules">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="账号" prop="username">
          <el-input
            v-model.trim="form.username"
            autocomplete="off"
            :disabled="form.id ? true : false"
          ></el-input>
        </el-form-item>
        <el-form-item label="重置密码" prop="setPassword" v-if="form.id">
          <el-input
            v-model="form.setPassword"
            auto-complete="new-password"
            minlength="6"
            maxlength="20"
            show-password
            placeholder="6-20"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password" v-else>
          <el-input
            v-model.trim="form.password"
            autocomplete="off"
            show-password
            auto-complete="new-password"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传头像" style="width: 100px">
          <div class="logo-src-box" v-if="form.avatar">
            <img :src="logoSrc" alt="" />
            <i @click="clearLogo" class="iconfont">&#xe651;</i>
          </div>
          <el-upload
            class="logo-uploader"
            v-else
            action="school/upload"
            name="avatar"
            :headers="headersParams"
            :show-file-list="false"
            :on-success="handleCoverSuccess"
            :before-upload="beforeCoverUpload"
          >
            <i class="el-icon-plus logo-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <span class="school-prompt"
          >建议尺寸：200*200；格式为.jpg或.jpeg或.png；大小不超过2M的图片</span
        >
      </el-form>
      <div class="dialog-footer" style="margin-top: 20px">
        <el-button @click="judgeDialogVisible = false">取 消</el-button>
        <el-button
          style="margin-left: 30px"
          type="primary"
          @click="confirmForm('form')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    
  </div>
</template>

<script>
import { showJudgeList, addJudge, delJudge } from "@/utils/apis";
export default {
  name: "Index",
  data() {
    var validateSetPass = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else {
        if (value.length < 6 || value.length > 12) {
          callback(new Error("长度在 6 到 12 个字"));
        } else {
          callback();
        }
      }
    };
    return {
      headersParams: {
        Authorization: localStorage.getItem("evaluationToken") || "",
      },
      hostUrl: localStorage.getItem("hosturl") || "",
      dialogTitle: "",
      judgeDialogVisible: false,
      logoSrc: "",
      judgePages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      form: {
        id: "",
        name: "",
        username: "",
        password: "",
        avatar: "",
        setPassword: "",
      },
      tableData: [],
      judgeRules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "长度在 2 到 10 个字符",
            trigger: "blur",
          },
        ],
        username: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            min: 3,
            max: 10,
            message: "长度在 3 到 10 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 12, message: "长度在6到20个字符", trigger: "blur" },
        ],
        setPassword: [{ validator: validateSetPass, trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.showJudgeListData();
  },
  methods: {
    showJudgeListData() {
      let params = {
        paging: 1,
        page: this.judgePages.currentPageNum,
        pageSize: this.judgePages.eachPageNum,
      };
      showJudgeList(params).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.judgePages.total = res.data.total;
        }
      });
    },
    createJudge() {
      this.dialogTitle = "添加评委";
      this.judgeDialogVisible = true;
    },
    confirmForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            nickname: this.form.name,
            username: this.form.username,
            avatar: this.form.avatar,
          };
          if (this.form.id) {
            params.id = this.form.id;
          }
          if (this.form.setPassword) {
            params.password = this.form.setPassword;
          }
          if (this.form.password) {
            params.password = this.form.password;
          }
          addJudge(params)
            .then((res) => {
              if (res.code === 200) {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.judgeDialogVisible = false;
                this.showJudgeListData();
              }
            })
            .catch((err) => {
              console.log("err", err);
            });
        } else {
          return false;
        }
      });
    },
    editJudge(row) {
      this.form.id = row.id;
      this.judgeDialogVisible = true;
      this.dialogTitle = "编辑评委";
    },
    deleteJudge(row) {
      this.$confirm(`是否删除该条评委信息`, "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "green-message-box",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: row.id,
          };
          delJudge(params).then((res) => {
            if (res.code === 200) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.showJudgeListData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    resetForm() {
      this.$refs.form.resetFields();
      this.logoSrc = null;
      this.form.avatar = "";
      this.form.id = "";
    },
    openJudge() {
      if (this.form.id) {
        let params = {
          id: this.form.id,
        };
        showJudgeList(params)
          .then((res) => {
            if (res.code === 200) {
              this.form.name = res.data.nickname;
              this.form.username = res.data.username;
              this.form.avatar = res.data.avatar;
              this.logoSrc = this.hostUrl + res.data.avatar;
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    },
    beforeCoverUpload(file) {
       const isLt2M = file.size/1024/1024 <2
      const isImg =
        file.name.toLowerCase().endsWith(".jpg") ||
        file.name.toLowerCase().endsWith(".jpeg") ||
        file.name.toLowerCase().endsWith(".png");
      if (!isImg) {
        this.$message.error("上传图片只能是 jpg 或 jpeg 或 png 格式!");
        return false;
      }else if(!isLt2M){
                  this.$message.error("上传图片大小不能超过2M！");
            }
      return isImg;
    },
    // 上传图片
    handleCoverSuccess(res, file) {
      if (res.code === 200) {
        this.logoSrc = this.hostUrl + res.data.src; // 有域名
        this.form.avatar = res.data.src; //无域名
        this.$message.success("头像上传成功");
        console.log(this.form.avatar);
      } 
      // else {
      //   this.$message.error("头像上传失败，请稍后再试~");
      // }
    },  
    // 删除logo
    clearLogo() {
      this.logoSrc = null;
      this.form.avatar = "";
    },
    CurrentChange(val) {
      this.judgePages.currentPageNum = val;
      let params = {
        paging: 1,
        page: this.judgePages.currentPageNum,
        pageSize: this.judgePages.eachPageNum,
      };
      showJudgeList(params).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.judgePages.total = res.data.total;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.judge-management {
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .judge-header {
    display: flex;
    justify-content: flex-end;
  }
  .logo-uploader {
    ::v-deep.el-upload {
      width: 80px;
      height: 80px;
      background: #eeeeee;
      display: flex;
      align-items: center;
      justify-content: center;
      .logo-uploader-icon {
        font-size: 32px;
        color: #a0a0a0;
      }
    }
  }
  .column-logo-box {
    .column-logo {
      width: 48px;
      height: 48px;
      border: 1px solid #eeeeee;
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      box-sizing: content-box;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .logo-src-box {
    display: flex;
    width: 80px;
    height: 80px;
    box-sizing: border-box;
    border: 1px solid #eeeeee;
    position: relative;
    img {
      max-width: 100%;
      max-height: 100%;
    }

    i {
      line-height: 1;
      font-size: 14px;
      color: #dcdcdc;
      position: absolute;
      right: -20px;
      top: 0;
      cursor: pointer;
    }
  }
  .school-prompt {
    color: #999;
    font-size: 12px;
    width: 200px;
    height: 200px;
  }
}
</style>